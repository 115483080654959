import { useState, useEffect, useMemo } from "react";
import {
  Typography,
  useDataGrid,
  DataGrid,
  List,
  CreateButton,
} from "@pankod/refine-mui";

export default function CustomList({
  title,
  columns,
  resource,
  canCreate,
  metas,
  dataGridParams = null,
  customDataGetter = null,
}) {
  // load custom parameters if there is a getter function ---------------------
  const columnsMemo = useMemo(() => columns, [columns]);

  const [loading, setLoading] = useState(true);
  const [customData, setCustomData] = useState(null);

  const loadCustomData = async () => {
    if (customDataGetter == undefined) {
      setLoading(false);
      return;
    }
    const data = await customDataGetter();
    setCustomData(data);
    setLoading(false);
  };

  useEffect(() => {
    loadCustomData();
  }, [customDataGetter]);
  // -------------------------------------------------------------------------

  // check for custom parameters
  const isFunction = (functionToCheck) => {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  };

  const params = isFunction(dataGridParams)
    ? dataGridParams(customData)
    : dataGridParams;

  //build datagrid
  let { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      resource: resource,
      /*metaData: {
        locale: currentLang.value || defaultLang,
        populate: "*",
        ...metas,
      },*/
      metaData: {
        populate: "*",
        ...metas,
      },
      ...params,
    });

  dataGridProps.rows = dataGridProps.rows.map((row) => {
    return {
      ...row,
      customData: customData,
    };
  });

  // bail out if loading
  if (loading)
    return (
      <List
        resource={resource}
        createButtonProps={{ size: "small" }}
        canCreate={canCreate}
        title={<Typography variant="h5">{title}</Typography>}
      >
        <div>Loading...</div>
      </List>
    );

  //render
  return (
    <List
      resource={resource}
      createButtonProps={{ size: "small" }}
      headerButtons={canCreate ? <CreateButton>New</CreateButton> : null}
      canCreate={canCreate}
      title={<Typography variant="h5">{title}</Typography>}
    >
      <DataGrid
        {...dataGridProps}
        columns={columnsMemo}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
