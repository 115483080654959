import { useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  Typography,
  IconButton,
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";

import { Draggable } from "@hello-pangea/dnd";

export default ({ item, position, onEditCard, onRemoveCard }) => {
  const truncateString = (str, num) => {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const [cardHovered, setCardHovered] = useState(false);
  const [contentSelected, setContentSelected] = useState(false);

  return (
    <>
      <Draggable draggableId={String(item.id)} index={position}>
        {(provided, { isDragging }) => {
          return (
            <Grid
              item
              xs={12}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              sx={{
                height: 150,
                position: "relative",
              }}
              onMouseEnter={() => setCardHovered(true)}
              onMouseLeave={() => setCardHovered(false)}
              onClick={(e) => {
                switch (e.detail) {
                  case 1:
                    break;
                  case 2:
                    setContentSelected(item);
                    break;
                }
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 5,
                  opacity: isDragging ? 0.9 : 1,
                  transform: isDragging ? "rotate(-2deg)" : "",
                }}
                elevation={isDragging ? 10 : 1}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: "100%" }}
                  p={2}
                >
                  <Typography variant="h5" align="center">
                    {item.title}
                  </Typography>
                </Grid>
                {/* <CardContent>
                <Typography variant="body2">
                  {truncateString(item.content, 50)}
                </Typography>
              </CardContent> */}
              </Card>
              <Grid
                container
                justifyContent="space-evenly"
                sx={{
                  position: "absolute",
                  width: 70,
                  top: -35,
                  right: -15,
                  transform: isDragging ? "rotate(-2deg)" : "",
                  opacity: cardHovered || isDragging ? 1 : 0,
                  transition: "all 0.3s ease",
                }}
              >
                <IconButton
                  sx={{
                    mt: 5,
                    height: 32,
                    width: 32,
                    backgroundColor: "#eaeaee",
                  }}
                  onClick={() => {
                    onEditCard(item.id);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  sx={{
                    mt: 5,
                    width: 32,
                    height: 32,
                    backgroundColor: "#eaeaee",
                  }}
                  onClick={() => {
                    onRemoveCard(item.id);
                  }}
                >
                  <Delete color="error" />
                </IconButton>
              </Grid>
            </Grid>
          );
        }}
      </Draggable>
      <Dialog
        open={contentSelected}
        onClose={() => {
          setContentSelected(false);
        }}
      >
        <Card>
          <CardHeader title={item?.title} />
          <CardContent>
            <Typography variant="body2">{item?.content}</Typography>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
};
