import { authProvider } from "authProvider";

export const accessControlProvider = {
  can: async ({ resource, action, params }) => {
    let user = await authProvider.getUserIdentity();
    if (!user)
      return Promise.resolve({
        can: false,
        reason: "Unauthorized",
      });
    let accessArray = params?.resource?.options?.access?.split(" ") || [];
    if (user.role.type == "admin") return Promise.resolve({ can: true });
    // // all access pages
    // if (resource == "dashboard" && user.role.type != "authenticated")
    // return Promise.resolve({ can: true });
    // default admin privileges
    // check if user has access to the page
    if (accessArray.includes(user.role.type))
      return Promise.resolve({ can: true });
    return Promise.resolve({
      can: false,
      reason: "Unauthorized",
    });
  },
};
