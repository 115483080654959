import {
  EditButton,
  DeleteButton,
  Stack,
  Chip,
  Typography,
} from "@pankod/refine-mui";

import { authProvider } from "authProvider";

export default {
  title: "projects",
  resource: "projects",
  canCreate: true,
  customDataGetter: async () => {
    let user = await authProvider.getUserIdentity();
    return user;
  },
  columns: [
    { field: "name", headerName: "name", flex: 1, minWidth: 300 },
    {
      field: "deadline",
      headerName: "deadline",
      flex: 1,
      minWidth: 50,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            <Typography>{params.row.deadline.slice(0, 10)}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "members",
      headerName: "members",
      flex: 1,
      minWidth: 200,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            {(params.row?.members || []).map((member, i) => (
              <Chip label={member.username} key={i} />
            ))}
          </Stack>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      minWidth: 50,
      renderCell: function render(params) {
        let user = params.row.customData;
        let isAdmin = user?.role?.type === "admin";
        return (
          <Stack direction="row" spacing={1}>
            {isAdmin && <EditButton hideText recordItemId={params.row.id} />}
            {isAdmin && <DeleteButton hideText recordItemId={params.row.id} />}
          </Stack>
        );
      },
    },
  ],
};
