import { useState, useEffect } from "react";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@pankod/refine-mui";

export default function MultiSelect({
  name,
  options,
  onChange,
  value,
  labels = [],
  variant,
  disabled,
}) {
  const [selected, setSelected] = useState(value ? value : []);

  useEffect(() => {
    //console.log(value);
    if (value && value.length > 0) {
      if (value[0].id) setSelected(value.map((o) => o.id));
      else setSelected(value);
    }
  }, [value]);

  const handleChange = (index) => {
    if (!selected?.includes(index)) {
      value = [...selected, index];
    } else {
      value = selected.filter((item) => item != index);
    }
    setSelected(value);
    //console.log(value);

    if (onChange) onChange(value);
  };

  return (
    <FormGroup sx={{ flex: 1 }}>
      <Grid container spacing={2}>
        {options.map((item) => {
          return (
            <Grid item xs={6} md={4} key={item.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={selected?.includes(item.value)}
                    onChange={() => {
                      handleChange(item.value);
                    }}
                  />
                }
                label={item.label}
              />
            </Grid>
          );
        })}
      </Grid>
    </FormGroup>
  );
}
