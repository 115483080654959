import { format, getTime, formatDistanceToNow } from "date-fns";

export const normalizeData = (data) => {
  // following code credits goes to roelbeerens see: https://forum.strapi.io/t/discussion-regarding-the-complex-response-structure-for-rest-graphql-developer-experience/13400/9
  const isObject = (data) =>
    Object.prototype.toString.call(data) === "[object Object]";

  const flatten = (data) => {
    if (!data.attributes) return data;

    return {
      id: data.id,
      ...data.attributes,
    };
  };

  if (Array.isArray(data)) {
    return data.map((item) => normalizeData(item));
  }

  if (isObject(data)) {
    if (Array.isArray(data.data)) {
      data = [...data.data];
    } else if (isObject(data.data)) {
      data = flatten({ ...data.data });
    } else if (data.data === null) {
      data = null;
    } else {
      data = flatten(data);
    }

    for (const key in data) {
      data[key] = normalizeData(data[key]);
    }

    return data;
  }

  return data;
};

export const updateFieldsWithRecordValues = (record, setValue) => {
  Object.keys(record).forEach((key) => {
    // if key is array or object, we update it
    if (
      record[key] &&
      (Array.isArray(record[key]) || typeof record[key] === "object")
    ) {
      // console.log("Updating", key);
      setValue(key, record[key]);
    }
  });
};

export const applyRecordToFields = (record, setValue) => {
  Object.keys(record).forEach((key) => {
    // console.log("Updating", key);
    setValue(key, record[key]);
  });
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy p");
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
