// i18n
import "locales/i18n";

// highlight
import "utils/highlight";

// scroll bar
import "simplebar/src/simplebar.css";

// editor
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

import { API_URL } from "./constants";

// refine
import {
  ErrorComponent,
  ReadyPage,
  CssBaseline,
  ThemeProvider,
  GlobalStyles,
  RefineSnackbarProvider,
  notificationProvider,
} from "@pankod/refine-mui";

// refine utils
import { Refine, Authenticated } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "strapiDataProvider";
import { authProvider } from "authProvider";
import { accessControlProvider } from "accessControlProvider";

// utils
import { ColorModeContextProvider } from "contexts";
import { AuthProvider } from "contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "utils/axios";

// custom pages and components
import CustomLayout from "components/CustomLayout";
import CustomCreateEdit from "components/CustomCreateEdit";
import CustomList from "components/CustomList";

import LoginPage from "components/user/LoginPage";
import RegisterPage from "components/user/RegisterPage";
import PasswordForgot from "components/user/PasswordForgot";
import PasswordReset from "components/user/PasswordReset";
import Dashboard from "pages/Dashboard";
import Settings from "pages/Settings";
import Unauthorized from "pages/Unauthorized";
import NotFound from "pages/NotFound";
import AccountDeleted from "pages/AccountDeleted";
import Privacy from "pages/Privacy";

// resources
import UsersListCFG from "pages/users/ListConfig";
import UsersFields from "pages/users/Fields";

import ProjectsListCFG from "pages/projects/ListConfig";
import ProjectsFields from "pages/projects/Fields";

import TagsListCFG from "pages/tags/ListConfig";
import TagsFields from "pages/tags/Fields";

import LinksListCFG from "pages/links/ListConfig";
import LinksFields from "pages/links/Fields";

import BoardsListCFG from "pages/boards/ListConfig";
import BoardsFields from "pages/boards/Fields";

import DocumentsListCFG from "pages/documents/ListConfig";
import DocumentsFields from "pages/documents/Fields";

import TicketsListCFG from "pages/tickets/ListConfig";
import TicketsFields from "pages/tickets/Fields";

// Icons

const queryClient = new QueryClient();
window.queryClient = queryClient;

const App = () => {
  return (
    <ColorModeContextProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Refine
              routerProvider={{
                ...routerProvider,
                routes: [
                  {
                    element: <RegisterPage />,
                    path: "/register",
                    layout: false,
                  },
                  {
                    element: <PasswordForgot />,
                    path: "/forgot-password",
                    layout: false,
                  },
                  {
                    element: <PasswordReset />,
                    path: "/reset-password",
                    layout: false,
                  },
                  {
                    element: <NotFound />,
                    path: "/not-found",
                    layout: false,
                  },
                  {
                    element: (
                      <Authenticated>
                        <Settings />
                      </Authenticated>
                    ),
                    path: "/settings",
                    layout: true,
                  },
                  // example of authorization with permissions
                  // {
                  //   element: (
                  //     <CanAccess action="customer" fallback={<Unauthorized />}>
                  //       <ProfileView />
                  //     </CanAccess>
                  //   ),
                  //   path: "/profile",
                  //   layout: false,
                  // },
                  {
                    element: <Privacy />,
                    path: "/privacy",
                    layout: false,
                  },
                  {
                    element: <AccountDeleted />,
                    path: "/account-deleted",
                    layout: false,
                  },
                ],
              }}
              resources={[
                // Custom Resources ----------------------
                // {
                //   name: "",
                //   icon: <Icon />,
                //   options: { label: "Label" },
                //   list: List,
                //   create: Create,
                //   edit: Edit,
                // },
                // Standard Resources --------------------
                // {
                //   name: "recource-endpoint",
                //   options: { label: "Resource Name" },
                //   list: ResourceList,
                //   create: () =>
                //     CustomCreateEdit({
                //       isCreate: true,
                //       Fields: ResourceFields,
                //     }),
                //   edit: () =>
                //     CustomCreateEdit({
                //       isCreate: false,
                //       Fields: ResourceFields,
                //     }),
                // },
                {
                  name: "users",
                  options: { label: "team", access: "admin", alwaysOn: true },
                  list: () => CustomList({ ...UsersListCFG }),
                  create: () =>
                    CustomCreateEdit({
                      isCreate: true,
                      Fields: UsersFields,
                    }),
                  edit: () =>
                    CustomCreateEdit({
                      routeName: "rooms",
                      isCreate: false,
                      Fields: UsersFields,
                    }),
                },
                {
                  name: "projects",
                  options: {
                    label: "projects",
                    alwaysOn: true,
                    access: "admin authenticated",
                  },
                  list: () => CustomList({ ...ProjectsListCFG }),
                  create: () =>
                    CustomCreateEdit({
                      isCreate: true,
                      Fields: ProjectsFields,
                    }),
                  edit: () =>
                    CustomCreateEdit({
                      routeName: "projects",
                      isCreate: false,
                      Fields: ProjectsFields,
                    }),
                },
                {
                  name: "tags",
                  options: {
                    label: "tags",
                    alwaysOn: true,
                    access: "admin authenticated",
                  },
                  list: () => CustomList({ ...TagsListCFG }),
                  create: () =>
                    CustomCreateEdit({
                      isCreate: true,
                      Fields: TagsFields,
                    }),
                  edit: () =>
                    CustomCreateEdit({
                      routeName: "tags",
                      isCreate: false,
                      Fields: TagsFields,
                    }),
                },
                {
                  name: "links",
                  options: {
                    label: "links",
                    alwaysOn: true,
                    access: "admin authenticated",
                  },
                  list: () => CustomList({ ...LinksListCFG }),
                  create: () =>
                    CustomCreateEdit({
                      isCreate: true,
                      Fields: LinksFields,
                    }),
                  edit: () =>
                    CustomCreateEdit({
                      routeName: "tags",
                      isCreate: false,
                      Fields: LinksFields,
                    }),
                },
                {
                  name: "boards",
                  options: {
                    label: "boards",
                    alwaysOn: true,
                    access: "admin authenticated",
                  },
                  list: () => CustomList({ ...BoardsListCFG }),
                  create: () =>
                    CustomCreateEdit({
                      isCreate: true,
                      Fields: BoardsFields,
                    }),
                  edit: () =>
                    CustomCreateEdit({
                      routeName: "boards",
                      isCreate: false,
                      Fields: BoardsFields,
                    }),
                },
                {
                  name: "documents",
                  options: {
                    label: "documents",
                    alwaysOn: true,
                    access: "admin authenticated",
                  },
                  list: () => CustomList({ ...DocumentsListCFG }),
                  create: () =>
                    CustomCreateEdit({
                      isCreate: true,
                      Fields: DocumentsFields,
                    }),
                  edit: () =>
                    CustomCreateEdit({
                      routeName: "documents",
                      isCreate: false,
                      Fields: DocumentsFields,
                    }),
                },
                {
                  name: "tickets",
                  options: {
                    label: "tickets",
                    alwaysOn: true,
                    access: "admin authenticated",
                  },
                  list: () => CustomList({ ...TicketsListCFG }),
                  create: () =>
                    CustomCreateEdit({
                      isCreate: true,
                      Fields: TicketsFields,
                    }),
                  edit: () =>
                    CustomCreateEdit({
                      routeName: "tickets",
                      isCreate: false,
                      Fields: TicketsFields,
                    }),
                },
              ]}
              authProvider={authProvider}
              accessControlProvider={accessControlProvider}
              dataProvider={DataProvider(API_URL + "/api", axios)}
              notificationProvider={notificationProvider}
              Title={({ collapsed }) => (
                <div
                  style={{
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {collapsed && (
                    <img src="/logo.png" alt="Logo" style={{ width: "50px" }} />
                  )}
                  {!collapsed && (
                    <img
                      src="/logo.png"
                      alt="Logo"
                      style={{ width: "100px" }}
                    />
                  )}
                </div>
              )}
              Layout={CustomLayout}
              DashboardPage={Dashboard}
              ReadyPage={ReadyPage}
              LoginPage={LoginPage}
              catchAll={<ErrorComponent />}
            />
          </AuthProvider>
        </QueryClientProvider>
      </RefineSnackbarProvider>
      <ToastContainer />
    </ColorModeContextProvider>
  );
};

export default App;
