import { Droppable } from "@hello-pangea/dnd";

import { Grid, Typography, IconButton } from "@pankod/refine-mui";
import AddIcon from "@mui/icons-material/Add";

import CardContainer from "./CardContainer";

export default ({ column, onAddCard, onEditCard, onRemoveCard }) => {
  return (
    <Grid
      container
      justifyContent="center"
      item
      xs={2}
      md={2}
      lg={3}
      sx={{
        height: column.items.length > 0 ? column.items.length * 150 : 0,
      }}
    >
      <Typography variant="h3">{column.title}</Typography>
      <Droppable droppableId={column.id}>
        {(droppableProvided, snapshot) => (
          <Grid
            container
            spacing={3}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {column.items.map((item, position) => (
              <CardContainer
                key={item.id}
                item={item}
                position={position}
                onEditCard={onEditCard}
                onRemoveCard={onRemoveCard}
              />
            ))}
            {droppableProvided.placeholder}
          </Grid>
        )}
      </Droppable>
      <Grid item xs={12} container justifyContent="center">
        <IconButton
          sx={{
            mt: 5,
            width: 40,
            height: 40,
          }}
          onClick={() => {
            onAddCard(column.id);
          }}
        >
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
