import { useEffect, useState } from "react";
import { nanoid } from "nanoid";

import {
  Grid,
  IconButton,
  Dialog,
  TextField,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from "@pankod/refine-mui";
import AddIcon from "@mui/icons-material/Add";

import { DragDropContext } from "@hello-pangea/dnd";

import ColumnContainer from "./ColumnContainer";

export default ({ columns: cols, columnTemplate, cardTemplate, onChange }) => {
  const [columns, setColumns] = useState([...cols]);

  const [currentCard, setCurrentCard] = useState(null);
  const [state, setState] = useState("create");

  useEffect(() => {
    let newCols = [...cols];
    for (let col of newCols) {
      if (col.id == null) col.id = nanoid();
      if (col.items == null) col.items = [];
      col.items.map((x) => {
        if (x.id == null) x.id = nanoid();
        return x;
      });
    }
    setColumns(newCols);
  }, [cols]);

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
    let sourceColumn = columns.find((x) => x.id == source.droppableId);
    let targetColumn = columns.find((x) => x.id == destination.droppableId);
    let item = sourceColumn.items[source.index];
    sourceColumn.items.splice(source.index, 1);
    targetColumn.items.splice(destination.index, 0, item);
    setColumns([...columns]);
    if (onChange) onChange(columns);
  };

  const onAddCard = (columnId) => {
    setState("create");
    setCurrentCard({
      id: nanoid(),
      ...cardTemplate,
      columnId,
    });
  };

  const onEditCard = (cardID) => {
    setState("edit", cardID);
    let containingColumn = columns.find((x) =>
      x.items.find((y) => y.id == cardID)
    );
    let card = containingColumn.items.find((x) => x.id == cardID);

    setCurrentCard({ ...card });
  };

  const handleCardEdit = (e) => {
    e.preventDefault();
    let form = new FormData(e.target);

    if (state == "create") {
      console.log("Creating", currentCard);
      let colmns = [...columns];
      let column = colmns.find((x) => x.id == currentCard.columnId);
      column.items.push({
        title: form.get("title"),
        content: form.get("content"),
      });
      setColumns(colmns);
      setCurrentCard(null);
      if (onChange) onChange(columns);
      return;
    }
    if (state == "edit") {
      let colmns = [...columns];
      let column = columns.find((x) =>
        x.items.find((y) => y.id == currentCard.id)
      );
      let card = column.items.find((x) => x.id == currentCard.id);
      card.title = form.get("title");
      card.content = form.get("content");
      setColumns(colmns);
      setCurrentCard(null);
      if (onChange) onChange(columns);
      return;
    }
  };

  const onRemoveCard = (cardID) => {
    const updatedColumns = [...columns];
    for (let column of updatedColumns) {
      const index = column.items.findIndex((item) => item.id === cardID);
      if (index !== -1) {
        column.items.splice(index, 1);
        break;
      }
    }
    setColumns(updatedColumns);
    if (onChange) onChange(updatedColumns);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={3}>
          {columns.map((column, i) => (
            <ColumnContainer
              key={i}
              column={column}
              onAddCard={onAddCard}
              onEditCard={onEditCard}
              onRemoveCard={onRemoveCard}
            />
          ))}
          <Grid item xs={3} md={3}>
            <Grid container justifyContent="center">
              <IconButton
                onClick={() => {
                  let newID = nanoid();
                  let template = { id: newID, ...columnTemplate, items: [] };
                  let newColumns = [...columns, template];
                  setColumns(newColumns);
                  console.log("new columns", newColumns);
                  if (onChange) onChange(newColumns);
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </DragDropContext>
      <Dialog
        maxWidth="xl"
        open={currentCard != null}
        onClose={() => {
          setCurrentCard(null);
        }}
        PaperProps={{
          sx: {
            borderRadius: 5,
          },
        }}
      >
        <Grid component="form" onSubmit={handleCardEdit}>
          <CardHeader title="Edit Card" />
          <Grid
            container
            justifyContent="center"
            sx={{
              p: 3,
              height: "100%",
            }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="title"
                variant="standard"
                label="Title"
                defaultValue={currentCard?.title}
              />
              <TextField
                fullWidth
                name="content"
                variant="standard"
                label="Content"
                defaultValue={currentCard?.content}
                multiline
                rows={8}
                sx={{
                  mt: 2,
                }}
              />
            </Grid>
          </Grid>
          <CardActions>
            <Grid container justifyContent="flex-end" spacing={5} padding={2}>
              <Grid item>
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setCurrentCard(null);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Grid>
      </Dialog>
    </>
  );
};
