// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const it = {
  demo: {
    title: `Italiano`,
    introduction: `Benvenuti nel sito di Psichiatria Transpersonale di Claudio Maddaloni.`,
  },
  docs: {
    hi: `Ciao`,
    description: `Serve Aiuto? \n Consulta la nostra documentazione.`,
    documentation: `documentazione`,
  },
  app: `app`,
  user: `utente`,
  list: `lista`,
  edit: `modifica`,
  shop: `negozio`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `carte`,
  posts: `posts`,
  create: `crea`,
  kanban: `kanban`,
  general: `generale`,
  banking: `banking`,
  booking: `booking`,
  profile: `profilo`,
  account: `account`,
  product: `prodotto`,
  invoice: `fattura`,
  details: `dettagli`,
  checkout: `checkout`,
  calendar: `calendario`,
  analytics: `analisi`,
  ecommerce: `e-commerce`,
  management: `gestione`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `descrizione`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default it;
