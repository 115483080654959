// @mui
import { enUS, itIT, ptPT } from "@mui/material/locale";

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.

export const allLangs = [
  {
    label: "Italiano",
    value: "it",
    systemValue: itIT,
    icon: "/flags/ic_flag_it.svg",
  },
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "/flags/ic_flag_en.svg",
  },
  {
    label: "Português",
    value: "pt",
    systemValue: ptPT,
    icon: "/flags/ic_flag_pt.svg",
  },
];

export const defaultLang = allLangs[0]; // Italian
