import { EditButton, DeleteButton, Stack, Chip } from "@pankod/refine-mui";

export default {
  title: "team",
  resource: "users",
  canCreate: true,
  columns: [
    {
      field: "username",
      headerName: "name",
      flex: 1,
      minWidth: 300,
    },
    { field: "email", headerName: "email", flex: 1, minWidth: 300 },
    {
      field: "projects",
      headerName: "projects",
      flex: 1,
      minWidth: 20,
      renderCell: function render(params) {
        return (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            width="100%"
          >
            <Chip label={params.row.projects?.length || 0} />
          </Stack>
        );
      },
    },
    {
      field: "tickets",
      headerName: "tickets",
      flex: 1,
      minWidth: 20,
      renderCell: function render(params) {
        return (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            width="100%"
          >
            <Chip label={params.row.tickets?.length || 0} />
          </Stack>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      minWidth: 50,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            <EditButton hideText recordItemId={params.row.id} />
            <DeleteButton hideText recordItemId={params.row.id} />
          </Stack>
        );
      },
    },
  ],
};
