import { EditButton, DeleteButton, Stack } from "@pankod/refine-mui";

import TagChip from "components/TagChip";

import { authProvider } from "authProvider";

export default {
  title: "links",
  resource: "links",
  canCreate: true,
  customDataGetter: async () => {
    let user = await authProvider.getUserIdentity();
    let currentProject = localStorage.getItem("project");
    return {
      project: currentProject,
      user: user,
    };
  },
  dataGridParams: (cData) => {
    return {
      permanentFilter: [
        { field: "projects", operator: "in", value: cData?.project },
      ],
    };
  },
  columns: [
    { field: "name", headerName: "Name", flex: 1, minWidth: 300 },
    {
      field: "tags",
      headerName: "Tags",
      flex: 2,
      minWidth: 500,
      renderCell: function render(params) {
        return (params.row?.tags || []).map((tag, i) => (
          <TagChip
            key={i}
            title={tag?.title}
            color={tag?.color}
            description={tag?.description}
          />
        ));
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      minWidth: 50,
      renderCell: function render(params) {
        let user = params.row.customData;
        let isAdmin = user?.role?.type === "admin";
        return (
          <Stack direction="row" spacing={1}>
            <EditButton hideText recordItemId={params.row.id} />
            {isAdmin && <DeleteButton hideText recordItemId={params.row.id} />}
          </Stack>
        );
      },
    },
  ],
};
