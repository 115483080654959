import React, { useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import axios from "utils/axios";
import { API_URL } from "constants";
import EditorToolbar, { formats } from "./EditorToolbar";
import ReactQuill from "react-quill";
import BlotFormatter from "quill-blot-formatter";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";

ReactQuill.Quill.register("modules/blotFormatter", BlotFormatter);
ReactQuill.Quill.register("modules/imageActions", ImageActions);
ReactQuill.Quill.register("modules/imageFormats", ImageFormats);

// BEGIN allow image alignment styles
const ImageFormatAttributesList = ["alt", "height", "width", "style"];

const BaseImageFormat = ReactQuill.Quill.import("formats/image");
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

ReactQuill.Quill.register(ImageFormat, true);
// END allow image alignment styles

const RootStyle = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
  "& .ql-container.ql-snow": {
    borderColor: "transparent",
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  "& .ql-editor": {
    minHeight: 200,
    maxHeight: 640,
    "&.ql-blank::before": {
      fontStyle: "normal",
      color: theme.palette.text.disabled,
    },
    "& pre.ql-syntax": {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
    },
  },
}));

// ----------------------------------------------------------------------

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  simple: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Editor({
  id = "minimal-quill",
  error,
  value,
  onChange,
  simple = false,
  helperText,
  sx,
  ...props
}) {
  const reactQuillRef = useRef(null);
  const imageHandler = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      console.log("files", input.files[0]);
      const formData = new FormData();
      formData.append("files", input.files[0]);
      // formData.append("subjectId", "123");
      // const hide = message.loading("uploading", 0);
      axios({
        method: "POST",
        url: `${API_URL}/api/upload`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(({ data }) => {
          const quill = reactQuillRef?.current?.getEditor();
          console.log(quill);
          const cursorPosition = quill.getSelection().index;
          const link = API_URL + data[0].url;
          // max-width: 100%;
          quill.insertEmbed(cursorPosition, "image", link);
          quill.setSelection(cursorPosition + 1);
          // hide();
          // delete element
          input.remove();
        })
        .catch((error) => {
          console.log(error);
          input.remove();
        });
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${id}`,
        handlers: {
          image: imageHandler,
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
      syntax: true,
      clipboard: {
        matchVisual: false,
      },
      blotFormatter: {},
    }),
    [id]
  );

  return (
    <div>
      <RootStyle
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        {!props.disabled && <EditorToolbar id={id} isSimple={false} />}
        <ReactQuill
          ref={reactQuillRef}
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder="Write something awesome..."
          {...props}
        />
      </RootStyle>

      {helperText && helperText}
    </div>
  );
}
