import PropTypes from "prop-types";
// @mui
import { FormHelperText } from "@mui/material";
//
import Editor from "./editor";

// ----------------------------------------------------------------------

RHFEditor.propTypes = {
  name: PropTypes.string,
};

export default function RHFEditor({ name, field, error, ...other }) {
  return (
    <Editor
      id={name}
      value={field.value || ""}
      onChange={field.onChange}
      error={!!error}
      helperText={
        <FormHelperText error sx={{ px: 2, textTransform: "capitalize" }}>
          {error?.message}
        </FormHelperText>
      }
      {...other}
      readOnly={other.disabled}
    />
  );
}
